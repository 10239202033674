.banner {
  background-image: url(../src/img/gas1.png);
  background-position: center;
  background-attachment: scroll !important;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 35rem;
  width: 100vw;
}

.btn-whatsapp {
  background-color: rgb(8, 184, 8);
  color: white;
  width: fit-content;
  padding: 4px 8px 4px 8px;
  border-radius: 10%;
}

.btn-whatsapp:hover {
  background-color: rgb(15, 124, 15);
  color: white;
  text-decoration: none;
  width: fit-content;
  padding: 4px 8px 4px 8px;
  border-radius: 9%;
}

@media (min-width: 1000px) {
  .banner {
    background-image: url(../src/img/gas1.png);
    background-position: center;
    background-attachment: fixed !important;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 35rem;
    width: 100vw;
  }
}

/* .banner2{
  background-image: url(beer-can-1462978185rtV.webp); 
background-image: url(../src/img/gas22.png);
 background-image: url(https://codingcat.codes/wp-content/uploads/2017/08/l1-1.png); 
background-position: center center;
background-repeat: no-repeat;
background-attachment: scroll;
background-size: cover;

height: 25rem;
} */
.img1 {
  height: auto;
  width: 100%;
}
.img2 {
  height: auto;
  width: 100%;
  /* max-height: 25rem;
  width: auto; */
}
.mask-dark {
  height: 100%;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.247);
}
.mask-white {
  height: 100%;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.034);
}

.jcc {
  display: flex;
  justify-content: center;
}

.alc {
  display: flex;
  align-items: center;
}

.fdc {
  display: flex;
  flex-direction: column;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.lift:hover {
  transition: all 0.3s;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.37);
  transform: translateY(-2%);
  -o-transition: translateY(-2%); /* opera */
  -ms-transition: translateY(-2%); /* IE 10 */
  -moz-transition: translateY(-2%); /* Firefox */
  -webkit-transition: translateY(-2%); /*safari and chrome */
}

.text-footer {
  font-size: 0.9rem;
}
.text-banner {
  display: inline;
}

.logo {
  height: 10rem;
  width: auto;
}

.footer {
  background-color: rgb(223, 102, 3);
}

.navBrand {
  text-decoration: none;
  color: black;
}

.navBrand:hover {
  text-decoration: none;
  color: black;
}

.spacing {
  height: 5rem;
}
.navLink {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(22, 22, 22, 0.856);
}

.navLink:hover {
  text-decoration: none;
  color: black;
}
#introLoc {
  height: 15rem;
  background-color: rgb(10, 10, 161);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.imgCo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.spb {
  justify-content: space-between;
}

.bgLogo {
  background-color: white;
}

.carr {
  height: 20rem;
}

@media (max-width: 350px) {
  .carr {
    height: 12rem;
  }
}

.bgCurveBanner {
  width: 100%;
  min-height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  /* will-change: transform, opacity, filter; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;

  /* background-color: red; */

  background-image: url(../src/assets/img/waveBanner2.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100%;
  /* width: 100vw !important; */
  /* overflow-x: hidden; */
  /* margin-bottom: 4rem; */
  /* min-height: 20rem; */
  z-index: 0 !important;
  display: flex;
}

.bgCurveCTop {
  width: 100%;
  min-height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  /* will-change: transform, opacity, filter; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;

  /* background-color: red; */

  background-image: url(../src/assets/img/waveCommentsTop2.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100%;
  /* width: 100vw !important; */
  /* overflow-x: hidden; */
  /* margin-bottom: 4rem; */
  /* min-height: 20rem; */
  z-index: 1;
  display: flex;
}

.bgCurveLTop {
  width: 100%;
  min-height: 100%;
  position: absolute !important;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: top;
  overflow: hidden;
  /* will-change: transform, opacity, filter; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  /* background-color: red; */

  background-image: url(../src/assets/img/wave.svg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100%;
  /* width: 100vw !important; */
  /* overflow-x: hidden; */
  /* margin-bottom: 4rem; */
  /* min-height: 20rem; */
  z-index: 1;
  display: flex;
}
@media (min-width: 760px) {
  .carr {
    height: 25rem;
  }
}
.carousel {
  width: 100%;
  height: auto;
  max-height: 35rem;
}
.carousel-item {
  overflow: hidden;
}
.carousel-item img {
  height: 100%;
  object-fit: scale-down;
  max-width: none;
  left: 50%;
  position: relative;
  bottom: relative;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

@media (min-width: 980px) {
  .carousel-item img {
    height: 100%;
    max-width: none;
    object-fit: scale-down;
    position: relative;
  }
}
.Ad {
  position: fixed;
  bottom: 0px;
  height: 2.5rem;
  background-color: whitesmoke;
  text-decoration: none;
}
