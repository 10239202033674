@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
.banner {
  /* background-image: url(beer-can-1462978185rtV.webp); */
  background-image: url(../src/img/gasNueva.jpeg);
  /* background-image: url(https://codingcat.codes/wp-content/uploads/2017/08/l1-1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  height: 100vh;
}

.text-swirvle{
  display: inline;
  font-family: 'Poppins', sans-serif;

  font-weight: 600;
  text-transform: none;
  color: #118df0;
}

.text-mariachi{
  color: orange;
}
.banner2 {
  /* background-image: url(beer-can-1462978185rtV.webp); */
  background-image: url(../src/img/bg-engine.jpg);
  /* background-image: url(https://codingcat.codes/wp-content/uploads/2017/08/l1-1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  /* width: "100%"; */
  /* height: auto; */
}

.disp {
  /* background-image: url(beer-can-1462978185rtV.webp); */
  background-image: url(../src/img/dispensario.png);
  /* background-image: url(https://codingcat.codes/wp-content/uploads/2017/08/l1-1.png); */
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  /* max-width: "100%"; */
  height: 30rem;
}
.prodBanner {
  /* background-image: url(beer-can-1462978185rtV.webp); */
  background-image: url(../src/img/products.png);
  /* background-image: url(https://codingcat.codes/wp-content/uploads/2017/08/l1-1.png); */
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  /* max-width: "100%"; */
  /* height: 30%; */
}

.prodpgBanner {
  /* background-image: url(beer-can-1462978185rtV.webp); */
  background-image: url(../src/img/gasNueva.jpeg);
  /* background-image: url(https://codingcat.codes/wp-content/uploads/2017/08/l1-1.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  /* max-width: "100%"; */
  /* height: 30%; */
}

/* .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
} */
/* .img1{
  height: auto;
  min-height: 3.5rem;
  width: 100%;
  
}
*/

.mask-dark {
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.25));
}

.jcc {
  display: flex;
  justify-content: center;
}

.alc {
  display: flex;
  align-items: center;
}

.fdc {
  display: flex;
  flex-direction: column;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.lift:hover {
  transition: all 0.3s;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.37);
  transform: translateY(-2%);
  -o-transition: translateY(-2%); /* opera */
  -ms-transition: translateY(-2%); /* IE 10 */
  -moz-transition: translateY(-2%); /* Firefox */
  -webkit-transition: translateY(-2%); /*safari and chrome */
}

.text-footer {
  font-size: 0.9rem;
}

.logo {
  height: 10rem;
  width: auto;
}

.logoFooter {
  height: 5rem;
  width: auto;
}

.footer {
  background-color: rgb(223, 102, 3);
}
.textOr {
  color: orange;
}
